/* External link */
@media screen {
    a[href*="//"]:not([href*="{{ .BaseURL }}"]):not(.external-clean) {

        @include fontawesome(after, $fontawesome-external-link-alt);

        svg {
            margin-left: 0.3rem;
            margin-right: 0.3rem;
            font-size: 0.8em;
        }
    }
}