/* Key points */
.keypoints {
    @extend .container;
    @extend .px-0;

    >.h1 {
        @extend .pb-3;
        @include font-size(1.5rem);
        font-weight: 400;
    }

    .card-list-container {
        .card-list {
            .card-list-item {

                .card {
                    @include gradient-directional($start-color: white, $end-color: white);

                    .card-image {
                        box-shadow: none;
                    }

                    .card-body {
                        @extend .py-2;

                    }

                    .card-title {
                        font-family: $tagline-font-family;
                    }
                }
            }
        }
    }

    // Resize fonts on smaller screens, otherwise the text will take up too much space, and columns
    // can take up more than one screen height.
    @include media-breakpoint-down(lg) {

        .card-title,
        .card-text {
            @include font-size(0.8rem);
        }
    }
}