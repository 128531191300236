.consent-form {
    @extend .text-end;

    button {
        @extend .btn;
        @extend .btn-primary;

        svg {
            @extend .me-1;
        }
    }
}

.consent-why {
    @extend .mb-1;
}

.privacy-settings {
    @extend .w-100;
    @extend .mt-3;

    .privacy-settings-header h2 svg {
        @extend .me-2;
    }

    .privacy-settings-subheader {
        @extend .lead;
    }

    .privacy-settings-text {
        @extend .mt-3;

        >form {
            .setting {
                @extend .form-control;
                @extend .form-switch;
                @extend .my-2;

                >input {
                    @extend .form-control;
                }

                >label {
                    @extend .form-label;
                }

                >div {
                    @extend .form-text;
                }
            }

            .save {
                @extend .text-center;
                @extend .text-md-end;

                >button {
                    @extend .btn;
                    @extend .btn-primary;
                    @extend .text-center;

                    svg {
                        @extend .me-2;
                    }
                }
            }
        }
    }
}

#compliance-modal {
    .modal-title {
        @extend .h4;
        font-weight: 300;

        svg {
            @extend .me-2;
        }
    }

    .modal-body {
        .privacy-settings-header {
            @extend .h2;

            font-weight: 300;
            font-size  : 1.2em;
        }

        .privacy-settings-subheader {
            font-weight: 300;
            font-size  : 1em;
        }

        .privacy-settings-text {
            @include font-size(0.85em);
        }
    }

    .modal-footer {
        button {
            font-weight: 300;

            svg {
                @extend .me-2;
            }
        }
    }
}