.feature {
    break-inside: avoid-page;
    page-break-inside: avoid;

    h3 {
        @extend .display-4;
        @include font-size(2rem !important);

        @include suppress-anchor();
    }

    .feature-image {
        @include card-border-shadow(0.5rem);
        @extend .my-2;

        img {
            width: 100%;
            height: 10rem;
            object-fit: contain;
            object-position: center;
            @extend .p-3;
            background: $white;

            &.banner {
                object-fit: cover;
                @extend .p-0;
            }

            @include print-background();
            @extend .d-print-block;
        }
    }

    .feature-footer {
        @extend .mt-2;
        text-align: center;

        a {
            @media print {
                display: none!important;
            }
    
            @extend .btn;
            @extend .btn-primary;

            svg {
                @extend .ms-2;
            }
        }
    }

    .feature-text {
        @extend .my-4;
    }
}