body.table_of_contents-page {
    div.content {
        @extend .container-xl;

        h2 {
            @extend .mt-5;

            >svg.svg-inline--fa {
                @extend .pe-2;
            }
        }

        blockquote {
            @extend .my-5;
        }

        table {

            tr {
                @extend .border-top;
                @extend .border-bottom;
            }

            td,
            th {
                @extend .py-2;
            }

        }

        img {
            @extend .w-100;
        }

        >div.page-container {
            @extend .row;
            @extend .container-xl;

            >main {
                @extend .order-2;
                @extend .order-xl-1;
                @extend .col;

                @media print {
                    flex: 0 0 100%!important;
                    max-width: 100%!important;
                }
            }

            >nav {
                @extend .order-1;
                @extend .order-xl-2;
                @extend .col-xl-3;
                @extend .d-print-none;


                >ul {
                    font-size: 0.8rem;
                    @extend .sticky-top;

                    li {
                        list-style: none;
                        @extend .border-bottom;
                        @extend .py-1;

                        svg {
                            display: none;
                        }

                        a {
                            color: $unifyblack;
                            text-decoration: none;

                            &.active {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}