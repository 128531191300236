/* Menu overrides */
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}






nav.submenu {
    @media print {
        display: none!important;
    }

    @extend .mt-2;

    ul {
        @extend .nav;
        @extend .justify-content-center;
        @extend .px-3;


        li {
            @extend .nav-item;
            @extend .p-1;
            @extend .m-1;
            @extend .text-center;
            @include rfs(8rem, width);

            a {

                @extend .nav-link;
                @extend .text-dark;
                @extend .p-0;
                font-weight: 300;
                @include font-size(1.1rem);


                i,
                svg,
                img {
                    @extend .mx-auto;
                    display: block;
                    @include rfs(2rem, height);
                    @include rfs(2rem, width);
                    @include font-size(1.2rem);
                    border: none;
                    box-shadow: none!important;
                }

                >span {
                    @extend .text-wrap;
                    @extend .text-center;
                    font-family: $tagline-font-family;
                }

                >span:nth-of-type(2) {
                    @extend .visually-hidden;
                }

                &:hover {
                    @extend .text-primary;
                }
            }

            &.active {
                @extend .border-top;
                @extend .border-bottom;
                @extend .border-primary;

                a {
                    @extend .text-primary;
                    font-weight: 400;
                }

            }

            @include media-breakpoint-down(xl) {
                a {
                    @include font-size(0.9rem);
                }
            }
        }


    }
}