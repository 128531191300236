/* Box overlays */
$squarelength: 15rem;

.card-list-container.card-list-hover {
    .card-list {
        .card-list-item {
            .card {
                width: 100%;
                position: relative;
                display: inline-block;
                height: $squarelength;
                min-height: $squarelength;
                @extend .border-0;
                overflow: hidden;



                .card-image {
                    background-color: $white;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    z-index: 1;
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                }

                .card-header {
                    padding-top: 0;
                }
            }

            &:hover {
                .card {
                    .card-image {
                        opacity: 0.1;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}