.page-page {
    .header-jumbotron {
        #pagetitle-lead {
            @extend .text-center;

            ul {
                @extend .px-0;
                @extend .text-center;
                li {
                    @extend .text-uppercase;
                }
            }

            @include media-breakpoint-down(xl) {
                font-size: 0.8rem;
            }
        }


        #pagetitle-header {
            @extend .text-center;
            font-size: 2.5rem;

            @include media-breakpoint-up(xl) {
                font-size: 4rem;
            }
        }
    }
}