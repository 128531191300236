.breadcrumb {
    >li {
        @extend .breadcrumb-item;

        &:first-of-type {
            @media print {
                display: none !important;
            }

            &+li::before {
                @media print {
                    content: none;
                }
            }
        }

        svg {
            @extend .me-2;
        }
    }
}

.jumbotron-content .breadcrumb-container {

    .breadcrumb {
        background: transparent;
        @extend .text-dark;
        @extend .px-0;
        @extend .my-0;
        @extend .py-1;

        a {
            @extend .text-dark;
            text-decoration-line: none;
        }
    }
}