/* Concertinas */

/* Rotate dropdown buttons when collapsed */
// Note that this also does this for menu dropdowns



.shortcode.concertina {
    @extend .mb-5;


    .dropdown button,
    button[data-bs-toggle='collapse'] {
    
        svg {
            transition: all 0.5s;
        }
    
        &:not(.collapsed) svg {
            -webkit-transform: rotate(180deg);
            -moz-transform   : rotate(180deg);
            transform        : rotate(180deg);
        }
    }

    .concertina-heading {
        @extend .row;
        @extend .align-items-center;

        .title-column {
            @extend .col-12;
            @extend .col-md-8;
        }

        .collapse-button {
            @extend .col-12;
            @extend .col-md-4;
            @extend .text-center;
            @extend .text-md-end;
            @extend .my-2;

            @media print {
                display: none!important;
            }

            >button {
                @extend .ms-md-3;
                @extend .btn;
                @extend .btn-primary;


                svg {
                    @extend .ms-2;
                }
            }
        }

        .explanation {
            @extend .lead;
            @extend .border-top;
            @extend .mt-2;
            @extend .col-12;
        }
    }

    .concertina-content {
        @extend .container;
        @extend .mt-1;
    }
}