.page-logo {
    max-width: 40rem;
    max-height: 10rem;
    width: 100%;
    object-fit: contain;
    @extend .mx-auto;
    @extend .img-fluid;
    @extend .bg-white;
    @extend .p-3;
    @extend .mb-3;
    @extend .d-block;
}

.sitelogo,
.logo {
    @include rfs(90px, height);
    @extend .py-3;


    img {
        @include rfs(90px, height);
        transition: opacity 500ms;
    }

    img.reverse {
        opacity: 0;
    }
}




.sitelogo {
    img {
        position: absolute;
    }
}

img {
    max-width: 100%;
}

.topnav {
    @extend .px-3;
    @extend .px-xl-5;
    position: absolute;
    @extend .container-fluid;
    z-index: 200;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .d-flex;
    @extend .py-3;
    @include rfs(90px, height);
}


.partner-page .metadata,
.supportedproduct-page .metadata {
    @media print {
        display: none !important;
    }
}

#header {
    background-size: cover;

    >div {
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media-breakpoint-up(xl) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
    }

    background-position: center;

    @include print-background();
}

#header .jumbotron {
    background-color: transparent;
    @extend .py-2;
}

.feature-page {
    main {
        @extend .py-0;
    }

    .carousel-action {
        a {
            color: $unifywhite;
        }
    }
}

.burgermenu {
    color: white;
    @include font-size(2rem);
    transition: color 500ms;
    @extend .d-print-none;
}

body.menu-reverse,
body:has(div.carousel-item.active.menu-reverse:not(.carousel-item-start), div.carousel-item.carousel-item-next.menu-reverse) {
    .sitelogo {
        img.reverse {
            opacity: 1;
        }

        img.normal {
            opacity: 0;
        }
    }

    .burgermenu {
        color: $unifyblack;
    }

    .carousel-action a {
        color: $unifyblack;
    }
}

.offcanvas-menu {
    @extend .offcanvas;
    @extend .offcanvas-end;
    @extend .text-bg-dark;

    .contact-footer {
        @include font-size(1.5rem);
    }

    .btn-close {
        @extend .btn-close-white;
    }

    h5 {
        font-family: $tagline-font-family;
    }

    .menu {
        >ul {
            @extend .py-1;
            @extend .py-xl-2;
            @extend .ps-0;

            >li {
                @extend .py-1;
                @extend .d-block;

                break-inside: avoid;

                >.item>a {
                    @include media-breakpoint-up(xl) {

                        font-size: larger;
                    }
                }

                ul {
                    @extend .d-none;
                }
            }
        }

        li {

            a {
                color: $unifywhite;
                text-decoration: none;
                text-transform: uppercase;
                font-style: normal;
            }

            span.menu-current {
                @extend .visually-hidden;
            }

            list-style: none;

            &.active {
                >span>a {
                    font-style: italic;
                }


            }
        }

        form {
            @extend .d-flex;

            label {
                @extend .visually-hidden;
            }

            input {
                @extend .form-control;
                width: 10rem;
            }

            button {
                @extend .btn;
                @extend .btn-secondary;

                span {
                    @extend .visually-hidden;
                }
            }
        }
    }
}