blockquote {
    position: relative; 

    @extend .p-4;

    @include font-size(1.2rem);
    font-weight: 300;
    color: $dark;
    break-inside: avoid;

    background:
        linear-gradient(to right, $dark 4px, transparent 4px) 0 100%,
        linear-gradient(to left, $dark 4px, transparent 4px) 100% 0,
        linear-gradient(to bottom, $dark 4px, transparent 4px) 100% 0,
        linear-gradient(to top, $dark 4px, transparent 4px) 0 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    &::before, &::after {
        position: absolute;
        color: $dark;
        @include font-size(4rem);
        font-weight: 900;
    }

    &::before {
        content: '\201c';
        top: -2rem;
        left: 0;
    }

    &::after {
        content: '\201d';
        bottom: -2rem;
        right: 0;
    }

    a {
        color: $secondary;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    &.quote-card {
        margin-bottom: 7rem;
    }

    .quote {
        @extend .p-3;
        text-align: center;
        color: $body-color;

    }

    cite {
        position: absolute;
        @extend .ps-lg-5;
        @include font-size(0.8rem);
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0.5px;
        color: $body-color;


        margin-top: 2rem;
        font-weight: 300;

        @extend .d-flex;
        @extend .align-items-center;

        >.thumbnail {
            @extend .mx-3;


            >img {
                height: 100%;
                @extend .img-fluid;
                max-height: 5rem;
                object-fit: cover;
                @include card-border-shadow(1rem);
            }
        }

        .citesource {
            z-index: 1;
        }
    }
}