$fontawesome-chevron-circle-left: '\f137';
$fontawesome-chevron-circle-right: '\f138';
$fontawesome-external-link-alt: '\f35d';
$fontawesome-ellipsis-vertical: '\f142';

@mixin fontawesome($location, $content){
    &::#{$location} {
        content    : $content;
        font-family: "Font Awesome 5 Free";
        display    : none;
        font-weight: 900;
    }
}

.fa-primary {
    fill: $unifyblack!important;
}

.fa-secondary {
    fill: $unifyblurple!important;
}