.event-metadata {
    .card-body {
        @extend .row;
    }
}

.card-list-container {
    .card-list {
        .card-list-item.events-card {
            .card {
                .card-header {
                    @extend .pb-2;
                }
            }
        }
    }
}