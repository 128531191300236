
%ordinalvaluebase {
    @include media-breakpoint-down(lg) {
        max-width: 40%;
        font-size: 80%;
    }
    display: flex;
    width: fit-content;
    max-width: 20%;
}

.questionaire {
    .questionaireheader{
        @include font-size(1.5rem);
        @include media-breakpoint-down(lg) {
            @include font-size(1rem);
        }
        padding-right: -10px;


    }
    .required:after{
        content: "*";
        color: red;
        position: absolute;
        padding-left: 5px;
    }

    .radio-toolbar {
        margin: auto;
        width: 100%;
        padding: 10px;
        display: flex;  
        flex-wrap: wrap;
        justify-content: center;

        input[type="radio"] {
            opacity: 0 !important;
            width: 0%
        }

        input[type="radio"]:focus+label {
            border: 2px dashed $unifyblurple;
        }

        input:checked+label {
            background-color:  $unifyblurple;
            border-color:   adjust-color($color: $unifyblurple, $lightness: -12%);
            color: $unifywhite;
        }

        label{
            @extend .m-1;
            @extend .px-3;
            @extend .py-2;
            @extend .rounded-3;
            background-color: $unifywhite;
            text-align: center;
            font-size: 1.2rem;
            width: auto;
            flex:1;
            border: 2px solid $unifyblurple;
            &:hover{
                background-color: adjust-color($color: $unifyblurple, $lightness: 50%);
            }
        }

        .invalid{
            flex-basis: 100%;
        }
    }    

    .ordinal-box {
        @extend .px-1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        
        .min-value {
            @extend %ordinalvaluebase;
            margin-right: auto;
            text-align: left;
        }
    
        .max-value {
            @extend %ordinalvaluebase;
            margin-left: auto;
            text-align: right;
        }
    }

}

canvas#chartCanvas {
    height: 50vh;
    @extend .py-5;
}
