/* Pricing table */

.pricingtable {
    @extend .container;
    @extend .py-3;
    @extend .d-block;
    @extend .mb-4;

    .pricingtable-row {
        @extend .row;
        @extend .justify-content-center;

        .offercontainer {
            @extend .mt-1;
            @extend .col-xl-3;
            @extend .col-lg-4;
            @extend .col-md-6;
            @extend .col-12;

            break-inside: avoid-page;

            margin-bottom: 2rem !important;

            .offer.card {
                @extend .mb-5;
                @extend .mb-lg-0;

                @include box-shadow();

                transition: color 2s;
                height: 100%;

                &.bg-success {
                    background: none;

                    .card-body .card-title span {
                        color: $white;
                    }
                }

                &:hover {

                    .btn {
                        opacity: 1;
                    }


                    .card-title {
                        color: $primary !important;
                    }
                }

                .card-body {
                    @extend .p-1;
                }

                .card-title {
                    margin: 0.5rem 0;
                    @include font-size(1.1rem);
                    letter-spacing: .1rem;
                    font-weight: 400;
                    @extend .text-muted;
                    @extend .text-uppercase;
                    @extend .text-center;
                    @extend .d-block;

                    &[item='size'], &[item='level']
                    {
                        font-family: $tagline-font-family;
                    }

                    .price,
                    .period {
                        @extend .d-inline-block;


                    }
                }

                .card-footer {
                    @extend .text-center;

                    @media print {
                        display: none!important;
                    }
                }

                .card-price {
                    margin: 0;

                    .price {
                        @include font-size(2rem);
                    }

                    .period {
                        @include font-size(0.8rem);
                    }
                }
            }
        }
    }

    hr {
        margin: 1rem 0;
    }

    ul {
        margin-left: 2rem;
        li {
            margin-bottom: 1rem;
        }
    }

    .text-muted {
        opacity: 0.8;
    }

    .btn {
        @include font-size(80%);
        border-radius: 5rem;
        letter-spacing: .1rem;
        font-weight: bold;
        padding: 1rem;
        opacity: 0.7;
        transition: opacity 0.5s;

        @extend .btn-primary;
        @extend .text-uppercase;
    }
}