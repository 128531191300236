.did-usecases-page .metadata ul {
    @extend .d-block;
    @extend .text-center;


    li {
        list-style-type: none;
        @extend .d-inline-block;
        @extend .text-uppercase;
        @extend .px-3;
        color: $gray-600;
    }
}

.did-usecases-card .card-header ul, .did-usecases-page .table_of_contents-card .card-header ul {
    @extend .text-center;
    @extend .px-2;
    li {
        @extend .text-uppercase;
        list-style: none;
        color: $gray-600; 
        
    }
}