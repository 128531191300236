.banner {
    @media print {
        display: none!important;
    }


    &.banner-footer {
        @extend .position-relative;
        @extend .w-100;

        .banner-alert {
            @extend .fixed-bottom;
        }
    }

    #consent-alert {  
        @extend .alert-warning;
        @include media-breakpoint-up(lg){
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
        }
    }

    .banner-alert {
        @extend .alert; // note that this must also be declared in the HTML, or dismissing the alert doesn't work.
        @extend .alert-dismissible;
        @extend .fade;
        @extend .px-2;

        @extend .mb-0;

        .banner-alert-header {

            .banner-alert-header-text {
                @extend .alert-heading;
                @include font-size(0.95em);
                @extend .mb-0;
                font-weight: 300;
                @extend .p-0;
                @extend .me-5;
            }

            .banner-alert-close-button {
                @extend .btn-close;
                @extend .text-end;
                @extend .pt-2;
                @extend .pe-2;
            }
        }

        .banner-information {
            @include font-size(0.8em);
            font-weight: 300;
            @extend .mt-1;

            >button {
                @extend .btn;
                @extend .btn-link;
                @extend .btn-sm;
                @extend .ps-0;
            }
        }

        .banner-footer {
            @extend .text-end;


            button {
                @include font-size(0.8em);
                font-weight: 300;
                svg {
                    @extend .me-1;
                }
            }
        }

        .banner-information-collapse {
            @extend .small;
        }
    }
}