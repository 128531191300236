.microsoft-forms,
.pdf {
    @extend .ratio;
}

.microsoft-forms,
.pdf {
    @extend .ratio-1x1;
}

.video {
    @media print {
        display: none !important;
    }

    .video-content {
        position: relative; // This is necessary for the absolute positioning of the play button to work
    }

    @include card-border-shadow(0.5rem);

    @include media-breakpoint-up(lg) {
        width: 75%;
    }

    position: relative;

    @extend .mx-auto;
    @extend .my-5;

    &.playing {
        .video-title {
            opacity: 0.2;
        }

        .play-button {
            display: none;
            /* Hide play button once video starts playing */
        }
    }

    .video-title {
        @extend .lead;

        transition: opacity 1s;
        pointer-events: none;

        @extend .m-3;
        @extend .p-2;
        @include card-border-shadow(0.5rem);
        background: $whitealpha;
        color: $dark;

        z-index: 1;
        position: absolute;
        top: 0;

        svg {
            @extend .ms-2;
        }
    }

    .play-button {
        pointer-events: none;
        svg {
            pointer-events: auto;
            position: absolute;
            top: 50%; // Position 50% from the top
            left: 50%; // Position 50% from the left
            transform: translate(-50%, -50%); // Shift the button by half its own size to fully center it
            font-size: 80px;
            cursor: pointer;
            z-index: 2;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 1;
            }
        }
    }
}