.shortcode-button {
    @media print {
        display: none!important;
    }

    @extend .text-center;
    @extend .pb-5;

    > a {
        @extend .btn;
        @extend .btn-primary;
        @extend .btn-lg;
    }
}