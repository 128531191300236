%list-container {
    @extend .overflow-auto;
    @extend .scroll-snap-x;
    @extend .px-md-3;
    @extend .h-100;

    @include media-breakpoint-down(md) {
        $half-gutter: $grid-gutter-width / 2;
        margin-left : -$half-gutter;
        margin-right: -$half-gutter;
    }
}
