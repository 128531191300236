@mixin scroll-snap($direction) {
    .scroll-snap-#{$direction} {
        scroll-snap-type          : #{$direction} mandatory;
        -webkit-overflow-scrolling: touch;
    }
}

@mixin scroll-snap-center($direction, $selector) {
    .scroll-snap-#{$direction} #{$selector} {
        scroll-snap-align: center;
    }
}

@include scroll-snap(y);
@include scroll-snap(x);