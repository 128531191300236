/* This CSS allows the "slide effect" as seen on the main page. */

[data-slide='true'] {
    position         : -webkit-sticky; // Safari
    position         : sticky;
    top              : 0;
    scroll-snap-align: start;

    @include internet-explorer {
        position: relative;
    }
}

nav[data-slide='true'] {
    position: relative;
}

/* Position the footer on a data-slide page */
div#footer {
    pointer-events: none;
    @extend .vh-100;

    >div {
        position      : absolute;
        bottom        : 0;
        pointer-events: auto;

        @extend .container-fluid;
        @extend .bg-dark;
        @extend .text-white;
        @extend .rounded;
    }

    @include internet-explorer {
        height: 100% !important;
    }

    a {
        color: $light;
    }
}