%fillcoolgreen {
    fill: $unifycoolgreen;
    stroke: none;
}

%fillunifyblurple {
    fill: $unifyblurple;
    stroke: none;
}

.radar-chart {
    margin: auto;
    display: block;
    overflow: visible;

    .axis line,
    .level-group line {
        stroke-width: 2px;
        stroke: adjust-color($color: $unifyblurple, $lightness: 35%);
    }

    .Result .circle {
        stroke: adjust-color($color: $unifycoolgreen, $lightness: -25%);
        fill: adjust-color($color: $unifycoolgreen, $lightness: -15%);
    }

    .Unify .circle {
        @extend %fillunifyblurple;
    }

    .legend {
        &.right {

            @include media-breakpoint-down(md) {
                transform: translate(-3em, 0.5em);
            }
        }
    }

    .legend.right {
        text-align: right;
    }

    .area {
        fill-opacity: 0.7;

        &.Result {
            @extend %fillcoolgreen;

        }

        &.Unify {
            @extend %fillunifyblurple;
        }
    }

    &.focus {
        .area {
            &.focused {
                fill-opacity: 0.9;
            }
        }
    }
}