.paginator {  
    
    @media print {
        display: none!important;
    }

    >ul {



        @extend .pagination;
        @extend .justify-content-center;


        >li {
            @extend .page-item;
            @include card-border-shadow(1rem);

            &.active >a{
                background: $primary;
                color: $white;
            }

            >a {
                @extend .border-0;
                @extend .page-link;
                @extend .px-1;
                @extend .px-md-2;
                @extend .px-lg-3;

                >span {
                    @extend .visually-hidden;
                }
            }
        }
    }
}