/* Overrides for jumbotrons */

.jumbotron {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    break-inside: avoid-page;
    page-break-inside: avoid;

    @include print-background();

    @extend .py-5;
    @extend .d-flex;
    @extend .align-items-center;

    /* Page header jumbotrons */
    &.header-jumbotron {
        @extend .box-shadow-none;
        border-radius: 0;
        min-height: 20rem;
        @extend .py-5;
    }

    @include card-border-shadow($radius: 1rem);

    // For jumbotrons that need to be full height of the view window.
    &[data-jumbotron-fullheight='true'] {
        height: 100vh;
    }

    .container {
        // Sets up the jumbotron content
        position: relative;
        background: transparent;
        backdrop-filter: blur(0.25rem);
        padding: 2rem;

        // How to handle an image in the content
        img {
            max-height: 20rem;
            object-fit: contain;
            @include card-border-shadow($radius: 1rem);
        }
    }

    .jumbotron-content {
        @extend .container;
        color: $unifyblack;
        @include card-border-shadow($radius: 1rem);


        @extend .my-md-2;
        @extend .my-xl-5;

        background: $whitealpha;
        width: fit-content;

        .jumbotron-content-header {
            font-family: $headings-font-family;
            @include font-size(3rem);
            @include suppress-anchor();
        }

        .jumbotron-content-summary {
            @extend .lead;
            color: $unifyblurple;
        }

        .jumbotron-content-summary,
        .jumbotron-content-text {
            @extend .d-block;

            ul {
                @extend .d-flex;
                @extend .justify-content-center;
                @extend .flex-wrap;
                width: 100%;

                li {
                    list-style-position: inside;
                    list-style: none;

                    &:not(:first-child) {
                        &::before {
                            content: '|';
                            padding: 0.5em;
                        }
                    }
                }
            }
        }

        .jumbotron-content-text {
            @extend .my-4;
            @extend .d-block;
            font-family: $body-font-family;
        }

        .shortcode-button {
            @extend .pb-0;

            @media print {
                display: none !important;
            }

        }
    }
}

