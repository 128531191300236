/* Search results */


body#search {
    main {
        @extend .container;

        form {
            @extend .my-2;

            label {
                @extend .form-label;
            }

            > div {
                @extend .d-flex;
            }

            input {
                @extend .form-control;
            }

            button {
                @extend .btn;
                @extend .btn-primary;
                @extend .my-2;
                @extend .my-sm-0;

                span {
                    @extend .visually-hidden;
                }
            }
        }

        #search-results {
            @extend .row;

            .card {
                @include card-border-shadow(1.5rem);
                @extend .col-12;
                @extend .px-0;
                @extend .my-2;
                overflow: none;

                .card-header {

                    .card-title {
                        >ul {
                            @extend .breadcrumb;
                            @extend .align-items-center;

                            >li {
                                @extend .breadcrumb-item;
                                @extend .my-auto;

                                &:last-child {
                                    @extend .h5;
                                }
                            }
                        }
                    }

                    >div {
                        @extend .row;

                        >div {
                            @extend .col-12;

                            &:first-child {
                                @extend .col-md-4;
                                @extend .my-auto;
                            }

                            &:last-child {
                                @extend .col-md-8;

                                >div {
                                    @extend .progress;
                                    @extend .my-2;
                                    @extend .position-relative;
                                    @extend .bg-dark;

                                    >div {
                                        @extend .progress-bar;
                                    }

                                    >small {
                                        @extend .justify-content-center;
                                        @extend .d-flex;
                                        @extend .position-absolute;
                                        @extend .w-100;
                                        @extend .text-white;
                                        @extend .align-self-center;
                                    }
                                }
                            }
                        }
                    }
                }

                .card-body {
                    >div {
                        &:first-child {
                            @extend .d-inline;
                        }
                    }
                }
            }

            .alert {
                @extend .alert-warning;
                @extend .mt-5;

                >h2 {
                    @extend .alert-heading;
                }
            }
        }


        #searching {
            @extend .row;

            >div {
                @extend .h2;
                @extend .mt-5;
                @extend .col-12;
                @extend .text-center;

                svg {
                    @extend .me-5;
                }
            }
        }

        #search-error {
            @extend .alert;
            @extend .alert-danger;
            @extend .mt-5;

            >h2 {
                @extend .alert-heading;
            }
        }

        #search-more {
            @extend .text-center;

            >button {
                @extend .btn;
                @extend .btn-primary;
                @extend .my-2;
                @extend .my-sm-0;

                svg {
                    @extend .ms-2;
                }
            }
        }
    }
}