.mermaid {
    > svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    svg.svg-inline--fa {
        path {
            stroke: black !important;
            fill: black !important;

        }
    }
}