/* Employees */
.card-list-container,
.card-list-container.small {
    .card-list {
        .card-list-item.employees-card, .card-list-item.guest-speaker-card {
            @extend .p-1;
            @extend .m-0;

            position  : relative;
            display   : inline-block;
            height    : $squarelength;
            min-height: $squarelength;
            @extend .border-0;
            overflow: hidden;
            @extend .col-lg-3;
            @extend .col-11;


            .card {
                @extend .p-0;
            }

            .card-image {
                background-color: $white;
                z-index         : 1;
                top             : 0;
                height          : 100%;
                left            : 0;
                width           : 100%;
                box-shadow      : none!important;
            }

            .card-image,
            .card-header {
                position  : absolute;
                padding   : 1rem;
                opacity   : 1;
                transition: opacity 1s ease-in-out;
                padding   : 0;

                img {
                    object-fit: cover;
                    padding   : 0;

                    @media print {
                        max-width: 30rem;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            .card-header,
            .card-footer,
            .card-body {
                padding-right: 0.5rem;
                padding-left : 0.5rem;
            }

            .card-header {
                z-index: 2;
                @extend .mb-2;
                bottom         : 0;
                right          : 0;
                background     : $whitealpha2;
                backdrop-filter: blur(0.25rem);
                color          : white;
                @include card-border-shadow($card-border-radius);

                >h5,
                >h6 {
                    color      : black;
                    font-weight: 400;
                }

                >h5 {
                    @include font-size(1.4em);
                }

                >h6 {
                    @include font-size(0.8em);
                    @extend .lead;
                }
            }

            .card-footer {
                padding-bottom: 0.5rem;
            }

            .card-body {
                font-size  : 0.8em;
                padding-top: 0.5rem;
            }

            &:hover {
                .card {

                    .card-image,
                    .card-header {
                        opacity       : 0.1;
                        pointer-events: none;
                    }
                }
            }
        }


    }
}

#header .employees-card.card-list-item .card,
#header .guest-speaker-card.card-list-item .card
{
    max-width: 30rem;
    @extend .mx-auto;
}