@media print {
    h1, h2, h3, h4 {
        break-after: avoid;
    }

    td {
        break-inside: avoid;
    }

    body {
        --bs-body-font-size: 12px;
    }
}