/* Cards  */
$card-border-radius: 1rem;

.card-list-component {
    height: 100%;
}

.card-list-container {
    @extend %list-container;

    &:not(.card-list-columns) {
        @extend .container-fluid;

        .card-list {

            @extend .row;

            @extend .my-3;
            @extend .my-md-2;
            @extend .my-lg-1;
            @extend %horizontal-scroll;
            @extend .h-100;
            @extend .g-0;

            position: relative;

            .card-list-item {
                @extend .col-lg-4;
                @extend .col-md-6;
                @extend .col-sm-12;
                @extend .border-0;
                @extend .py-md-3;
                @extend .py-lg-2;
                @extend .py-0;
                @extend .my-2;
                @extend .my-lg-1;

                break-inside: avoid;

                @media print {
                    width: 100% !important;
                }

                @extend %snap-item-center;

                @include horizontal-scroll-breakpoint-down() {
                    width: 80%;
                }



                .card {
                    .card-image {
                        @extend .mb-2;
                    }
                }
            }
        }
    }

    .card-list {
        .card-list-item {
            @extend .my-4;

            .card {
                /* Cards will narrow to fit all on the screen unless we set a width - so this is set on small down.
                    The width allows for a user to see there are other cards they can swipe to on a touch-screen */
                @extend .h-100;
                @extend .p-3;
                @extend .border-0;
                color: $black;

                .card-image {
                    order: -1;
                    background: $white;
                    height: 6rem;


                    img,
                    svg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        padding: 1rem 0 1rem 0;


                        &.banner {
                            padding: 0;
                            object-fit: cover;
                            @include card-border-shadow($card-border-radius);
                        }

                        @extend .d-print-block;
                    }
                }

                .card-header {
                    border: none;
                    background: transparent;
                    margin: 0 0 1rem 0;
                    padding: 0;
                    padding-top: 1rem;
                    text-align: center;

                    h5 {
                        @include suppress-anchor();
                    }

                    .card-title {
                        font-weight: 300;
                        @include font-size(1.5rem);


                    }

                    .lead {
                        @include font-size(1rem);
                    }

                    h6 {
                        @include font-size(1rem);
                        @extend .lead;
                        color: $gray-600;
                        letter-spacing: 0.1rem;
                    }
                }

                .card-body {
                    padding: 0 0 2rem 0;
                    margin: 0;
                }

                .card-footer {
                    background-color: transparent;
                    @extend .border-0;
                    @extend .mx-0;
                    @extend .px-0;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    @extend .text-end;

                    svg {
                        @extend .ms-1;
                    }

                    span {
                        @extend .me-3;

                        a {
                            @media print {
                                display: none !important;
                            }

                        }
                    }
                }
            }
        }


    }

    &.small {
        .card-list {
            .card-list-item {
                .card {
                    .card-image {
                        height: 4rem;

                        img,
                        svg {
                            padding: 0.2rem;

                            &.banner {
                                padding: 0;
                            }
                        }
                    }

                    .card-header {
                        @extend .pt-1;

                        h5 {
                            @include font-size(1rem);
                        }

                        .lead {
                            @include font-size(0.8rem);
                        }

                        h6 {
                            @include font-size(0.8rem);
                        }
                    }

                    .card-body {
                        @extend .d-none;
                    }
                }
            }
        }
    }
}

.filter-form {
    .formgroup {

        &:nth-of-type(2) {
            @media print {
                display: none !important;
            }
        }

        .filter-label {
            @extend .d-none;
            @extend .d-print-inline;
        }

        >input {
            @media print {
                border-style: none;
                box-shadow: none;
            }
        }

        .btn {
            @extend .my-2;
        }
    }
}

#header {
    .card-list {
        @extend .justify-content-center;

    }
}