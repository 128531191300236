div.shortcode-grid.feature-grid>div.row>div>div>form.contact>div.disclaimer div.form-disclaimer>p,
.contact>.disclaimer>.form-disclaimer,
.form-disclaimer {
    @extend .small;
    @extend .pt-1;
}

.form-recaptcha-required {
    @extend .alert-warning;
    @extend .my-2;
    @extend .py-2;

    svg {
        @extend .mx-2;
    }

    #form-consent-info {
        @extend .my-2;

        button>svg {
            @extend .me-2;
            @extend .ms-2;
        }
    }
}

form.contact {
    @extend .mb-3;
    max-width: 1920px;

    @media print {
        display: none !important;
    }

    .valid-feedback,
    .invalid-feedback {
        font-weight: 700;
    }

    .form-group {
        @extend .py-2;
    }

    &.simple-email {
        >.form-row {
            @extend .row;

            >div {
                &:first-child {
                    @extend .col-8;
                    @extend .pe-0;
                }

                &:nth-child(2) {
                    @extend .col-4;
                    @extend .ps-0;
                }

                &:nth-child(n+3):nth-child(-n+4) {
                    @extend .col-12;
                }

                &:nth-child(4) {
                    @extend .small;
                }
            }
        }
    }

    .disclaimer {
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .flex-xxl-nowrap;

        >div {
            @extend .mx-2;

            &.required {
                @extend .small;

                .form-recaptcha-required {
                    @extend .px-2;

                    svg {
                        float: left;
                    }

                    button svg {
                        float: none;
                    }

                }
            }

            &.submit {
                @extend .mt-1;
                @extend .text-center;

                >button {
                    width: 5rem;
                    @extend .btn;
                    @extend .btn-primary;
                }
            }
        }
    }

    [data-form] {
        @extend .py-2;
        @extend .align-items-center;

        &[data-form='sending'] {
            @extend .mx-3;
        }
    }



    [data-form='success'] {
        >div>svg {
            color: $unifyblue;
        }
    }

    [data-form='failure'] {
        >div>svg {
            color: $unifyorange;
        }
    }

    [data-form='success'],
    [data-form='failure'] {
        @extend .flex-nowrap;
        @extend .justify-content-end;

        >div:nth-child(1) {
            @extend .flex-shrink-1;
            @extend .me-2;
        }
    }
}

input {
    font-family: $body-font-family;
}

/* Visibility of form elements based on consent classes on body element */
body {
    [data-form-consent='true'] {
        @extend .d-none;
    }

    [data-form-consent='false'] {
        @extend .d-block;
    }

    [data-form='sending'],
    [data-form='success'],
    [data-form='failure'] {
        @extend .d-none;
    }

    [data-form=''] {
        @extend .d-block;
    }

    &.consent-recommended {
        [data-form-consent='true'] {
            @extend .d-block;
        }

        [data-form-consent='false'] {
            @extend .d-none;
        }
    }

    form.form-sending {
        [data-form=''] {
            @extend .d-none;
        }

        [data-form='sending'] {
            @extend .d-block;
        }
    }

    form.form-success {
        [data-form='success'] {
            @extend .d-flex;
        }
    }

    form.form-failure {
        [data-form='failure'] {
            @extend .d-flex;
        }
    }
}