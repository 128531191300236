/* Customer cards */
.card-list-container {
    .card-list {
        .card-list-item.customer-card {
            .card {
                .card-image {
                    img {
                        border-radius: 0;
                        box-shadow: none;
                        @extend .p-1;
                    }
                }
            }
        }
    }
}