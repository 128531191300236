#carousel-1 {
    .got-a-minute-slide {
        img {
            transform: scale(1.2);
            object-position: right;
        }
    }

    .plan-slide {
        img {
            object-position: center;
        }
    }

    .password-slide {
        img {
            object-position: bottom 0px right 0px;
        }

        .carousel-caption {
            @media (min-width: 2160px)
            {
                transform: translateY(-100%)!important;
            }
        }
    }

    .are-you-next-slide {
        .carousel-caption {

            @include font-size(6rem);

            @media (min-width: 800px)
            {
                @include font-size(3rem);
            }

            @media (min-width: 1920px){
                @include font-size(8rem);
            }

            @media (min-width: 2561px){
                @include font-size(12rem);
            }
        }
    } 
    
    .unifyelevate-slide {
        .carousel-caption {
            @media (max-width: 1000px){
                right: inherit;
                top: 10% !important;
                bottom: inherit;
                left: inherit;

                transform: inherit;            
            }
        }
    }
}

