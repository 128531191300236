.footer-block {
    @extend .container-fluid;
    @extend .justify-content-center;
    @extend .mx-md-0;

    nav#largemenu {
        @extend .py-5;
        background-color: $unifyblack;
        color: $unifywhite;
        @extend .d-print-none;

        @extend .row;

        .largemenu-logosearch {
            @extend .col;

            > div {
                @extend .px-3;
                @extend .px-xl-5;
                @extend .py-5;
            }

            form {
                @extend .d-flex;
                @extend .justify-content-center;
    
                label {
                    @extend .visually-hidden;
                }
    
                input {
                    @extend .form-control;
                    width: 10rem;
                    max-width: 12rem;
                    @extend .flex-xl-fill;
                }
    
                button {
                    @extend .btn;
                    @extend .btn-secondary;
    
                    span {
                        @extend .visually-hidden;
                    }
                }
            }            

            .logo {
                @extend .d-flex;
                @extend .justify-content-center;
            }
        }

        .menu {
            @extend .col-lg-9;

            >ul {
                column-width: 20em;
                @extend .py-1;
                @extend .py-xl-2;
                @extend .ps-0;

                >li {
                    @extend .py-2;
                    @extend .py-xl-3;
                    @extend .d-block;

                    break-inside: avoid;

                    >.item>a {
                        @include media-breakpoint-up(xl) {

                            font-size: larger;
                        }
                    }
                }
            }

            li {

                a {
                    color: $unifywhite;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-style: normal;
                }

                span.menu-current {
                    @extend .visually-hidden;
                }

                list-style: none;

                &.active {
                    >span>a {
                        font-style: italic;
                    }


                }
            }
        }


    }
}

.footer {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;

    background-color: white;


    @include font-size(0.8em);
    @extend .mx-2;
    @extend .mx-xl-5;
    @extend .my-3;

   span:not(.copyright){
    @extend .d-print-none;
   }
}

.contact-footer {
    @extend .container-fluid;
    @extend .text-center;
    @extend .py-3;

    color: $unifywhite;
    background-color: $unifyblue;
    font-family: $tagline-font-family;

    @include font-size(2rem);

    @include media-breakpoint-up(xxl) {
        @include font-size(3rem);
    }

    a {
        color: $unifywhite;
    }
}