$unifyblack: #101820;
$unifyblue: #49c7ed;
$unifywhite: #ffffff;
$unifycoolgreen: #9cdbd9;
$unifyblurple: #44499c;
$unifyorange: #f87c56;
$unifyyellow: #ffbf3f;
$textcolor: adjust-color($unifyblack, $lightness: 15%);

$primary: $unifyblurple;
$dark : $unifyblack;
$secondary: $unifyblue;
$info : adjust-color($unifyblurple, $alpha: -0.2);
$warning: adjust-color($unifyorange, $alpha: -0.2);
$danger: adjust-color($unifyorange, $alpha: -0.2);

$navbar-light-active-color: $unifywhite;

$enable-shadows : true;
$enable-gradients: true;

$enable-font-sizes: true;

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0px,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1350px,
  xxxl: 1554px
);

/* Font overrides */
$bs-headings-font-weight : 700;

$headings-font-family: 'Baskervville', serif;
$body-font-family: 'Montserrat', sans-serif;
$tagline-font-family: 'Bebas Neue', sans-serif;
$font-family-base: $body-font-family;