.shortcode-grid {
    @extend .container;

    .row {
        @extend .align-items-start;

        > div {
            @extend .col-12;
            @extend .col-xl-6;
            @extend .my-4;
           
            &.hr {
                @extend .col-12;
            }

            .header-jumbotron:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.6), rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.6));
            }
        }
    }
}