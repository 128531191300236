$feature-grid-break: xl;

.shortcode-grid.feature-grid {
    @extend .m-0;
    max-width: 100vw;

    &.stagger .row:nth-child(even)>div:nth-child(even) {
        @include media-breakpoint-up($feature-grid-break) {
            order: -1;
        }
    }



    > .row {
        @extend .align-items-stretch;

        .highlight {
            @extend .ps-3;
            @extend .ps-xxl-5;


            &,
            p {
                font-family: $tagline-font-family;

                @include font-size(2.5rem);
                line-height: rfs-fluid-value(2.8rem);

                @include media-breakpoint-up(xxl) {
                    @include font-size(3rem);
                    line-height: rfs-fluid-value(3.5rem);
                }

                @extend .m-0;
            }

            &.large-highlight>div {

                &,
                p {
                    @include font-size(11rem);
                    line-height: 110%;
                }
            }
        }



        &.info-row {
            >div>div {
                h1 {
                    @include font-size(3rem);

                    @include media-breakpoint-up(xxl) {
                        @include font-size(4rem);
                    }

                    @extend .mb-5;
                }


                ul {
                    li {
                        border-left-style: none;
                        @extend .mx-2;

                        svg {
                            @extend .pe-2;
                        }
                    }
                }

                .two-cols {
                    @include media-breakpoint-up($feature-grid-break) {
                        columns: 2;
                        column-gap: 6rem;

                        p {
                            break-inside: avoid-column;
                        }
                    }
                }
            }
        }

        >div {
            @extend .py-5;
            min-height: 33vh;

            @include media-breakpoint-up($feature-grid-break) {
                min-height: 50vh;
            }

            display: flex;
            align-items: center;
            @extend .my-0;

            &.fullrow {
                width: 100%;

                &.fullrow-center {

                    @extend .justify-content-center;
                }
            }


            &.cover-image {
                @extend .p-0;
                @extend .d-flex;
                @extend .align-self-stretch;
                @extend .align-items-stretch;

                &.image-h-50vh {
                    >div {

                        img,
                        picture {
                            height: 50vh !important;
                        }
                    }
                }

                >div {
                    @extend .p-0;
                    min-height: 50vh;
                    height: 100%;
                    width: 100%;
                    @extend .d-flex;
                    @extend .flex-column;



                    img,
                    picture {
                        @extend .flex-grow-1;
                        max-height: 100%;
                        max-width: 100%;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                &.horizontal-flip {
                    img {
                        transform: scaleX(-1);
                    }
                }
            }

            >div {
                @extend .p-4;
                @extend .p-xl-5;

                h1,
                h2 {
                    font-family: $tagline-font-family;

                    @include font-size(5rem);

                    @include media-breakpoint-up(xxl) {
                        @include font-size(8rem);
                    }

                }

                h1:has(+ h2) {
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }

                &,
                p {
                    @include font-size(1rem);

                    @include media-breakpoint-up(xxl) {
                        @include font-size(1.2rem);
                    }

                    line-height: rfs-fluid-value(200%);
                }

                a {
                    text-decoration: none;
                    color: $unifyblack;
                    font-weight: bold;
                }

                ul {
                    font-family: $tagline-font-family;

                    @include font-size(1.5rem);

                    @include media-breakpoint-up(xxl) {
                        @include font-size(2rem);
                    }

                    @extend .px-0;



                    li {
                        list-style: none;
                        @extend .mx-xl-3;
                        @extend .d-block;
                        @extend .d-xl-inline-block;


                    }
                }
            }
        }


    }
}

.layer,
.shortcode-grid.feature-grid .row>div.layer {
    color: $white;
    background-size: cover;


    h1,
    h2 {
        a {
            color: $white !important;
        }
    }

    >div {
        margin-top: 5rem;
        margin-bottom: 5rem;

        ul {
            >li {
                @extend .px-xl-3;
                @extend .mx-0;

                @include media-breakpoint-up($feature-grid-break) {
                    border-left-width: 5px;
                    border-left-style: solid;
                }

                &:first-child {
                    @extend .ps-0;
                    border-left-style: none;
                }

                &:last-child {
                    @extend .pe-0;
                }
            }
        }
    }


}

.partners {
    &.partners-right {
        ul {
            @extend .justify-content-end;
        }
    }

    &.partners-align-top {
        ul {
            @extend .align-items-start;
        }
    }

    @extend .pt-5;

    ul {
        @extend .ps-0;
        @extend .d-xl-flex;
        @extend .justify-content-between;
        @extend .align-items-center;

        li {
            @extend .text-center;
            list-style: none;
            @extend .my-5;

            svg {
                height: 3.5em;
            }
            img, svg {
            
                @include media-breakpoint-up(md) {
                    max-width: 75%;
                }

                @include media-breakpoint-up(xl) {
                    width: rfs-fluid-value(200px);
                }
            }

            border-left-style: none;
        }
    }
}

$sxgawidth: 1280px;
$fhdwidth: 1920px;
$qhdwidth: 2560px;

@mixin backgroundsizes($src, $ext){
    @media (max-width: $sxgawidth) {
        background-image: url($src + '-sxga' + $ext);        
    }

    @media (min-width: ($sxgawidth + 1px)) and (max-width: $fhdwidth) {
        background-image: url($src + '-fhd' + $ext);        
    }

    @media (min-width: ($fhdwidth + 1px)) and (max-width: $qhdwidth){
        background-image: url($src + '-qhd' + $ext);        
    }

    @media (min-width: ($qhdwidth + 1px)) {
        background-image: url($src + '-uhd' + $ext);        
    }    
}

.layer-1 {
    @extend .layer;

    @include backgroundsizes('/static/img/layers/3d-layers-1', '.jpg');

    background-color: $unifyblurple;

    &.mirror {
        background-position: right;
        @include backgroundsizes('/static/img/layers/3d-layers-1-mirror', '.jpg');
    }
}

.layer-2 {
    @extend .layer;
    @include backgroundsizes('/static/img/layers/3d-layers-2', '.jpg');

    background-color: $unifyblack;

    &.mirror {
        @include backgroundsizes('/static/img/layers/3d-layers-2-mirror', '.jpg');
    }
}

.layer-3 {
    @extend .layer;
    @include backgroundsizes('/static/img/layers/3d-layers-3', '.jpg');
    background-color: $unifyblue;

    &.mirror {
        @include backgroundsizes('/static/img/layers/3d-layers-3-mirror', '.jpg');
    }    
}

.layer-4 {
    @extend .layer;
    @include backgroundsizes('/static/img/layers/3d-layers-4', '.jpg');
    background-color: $unifycoolgreen;

    &.mirror {
        @include backgroundsizes('/static/img/layers/3d-layers-4-mirror', '.jpg');
    }    
}

.layer-5 {
    @extend .layer;
    @include backgroundsizes('/static/img/layers/3d-layers-5', '.jpg');
    background-color: $unifyorange;

    &.mirror {
        @include backgroundsizes('/static/img/layers/3d-layers-5-mirror', '.jpg');
    }
}

.layer-6 {
    @extend .layer;
    @include backgroundsizes('/static/img/layers/3d-layers-6', '.jpg');
    background-color: $unifyyellow;


    &.mirror {
        @include backgroundsizes('/static/img/layers/3d-layers-6-mirror', '.jpg');
    }
}

html.webp {
    .layer-1 {
        @include backgroundsizes('/static/img/layers/3d-layers-1', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-1-mirror', '.webp');

        }
    }    

    .layer-2 {
        @include backgroundsizes('/static/img/layers/3d-layers-2', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-2-mirror', '.webp');

        }
    }    

    .layer-3 {
        @include backgroundsizes('/static/img/layers/3d-layers-3', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-3-mirror', '.webp');

        }
    }  

    .layer-4 {
        @include backgroundsizes('/static/img/layers/3d-layers-4', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-4-mirror', '.webp');

        }
    }  

    .layer-5 {
        @include backgroundsizes('/static/img/layers/3d-layers-5', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-5-mirror', '.webp');

        }
    }  

    .layer-6 {
        @include backgroundsizes('/static/img/layers/3d-layers-6', '.webp');
    
        &.mirror {
            @include backgroundsizes('/static/img/layers/3d-layers-6-mirror', '.webp');

        }
    } 

}










.bg-grey-300 {
    background-color: $gray-300;
}

.bg-grey-100 {
    background-color: $gray-100;
}

.img-text {
    @extend .my-4;
    @extend .d-flex;
    @extend .align-items-center;

    >div {
        @extend .mx-3;
    }

    .text {
        width: 33%;
    }
}

.shortcode-grid.feature-grid .row .industry,
.industry {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-evenly;


    li {
        @extend .d-flex;
        @extend .flex-column;

        @extend .align-items-center;

        span {
            @extend .d-block;
            @include font-size(1rem);
            font-weight: bolder;
            @extend .p-1;

            img {
                width: 100px;
            }
        }
    }
}