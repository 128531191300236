/* Table formatting */
main table {
    margin-bottom: 1rem;

    thead {
        background: $primary;
        color     : $white;
    }

    th, td {
        padding-left : 0.5em;
        padding-right: 0.5em;
    }

    tbody tr:nth-child(even) {
        background: $light;
    }
}