@mixin internet-explorer() {

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        @content;
    }
}

.lead {
    font-family: $tagline-font-family;
}

$darkalpha : adjust-color($dark, $alpha: -0.1);
$darkalphashadow: adjust-color($dark, $alpha: -0.5);
$lightalpha : adjust-color($light, $alpha: -0.1);
$whitealpha : adjust-color($white, $alpha: -0.2);
$whitealpha2: adjust-color($whitealpha, $alpha: -0.2);

@media print {
    a {
        text-decoration: none;
    }
}


/* Mixins */
@mixin box-shadow() {
    box-shadow: 0 0.5rem 0.5rem 0 $darkalphashadow;
}

@mixin box-shadow-none() {
    box-shadow: none;
}

.box-shadow-none {
    @include box-shadow-none();
}

@mixin card-border-shadow($radius) {
    @include box-shadow();
}

@mixin card-background() {
    @include gradient-directional($start-color: $gray-100, $end-color: $gray-300);
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin horizontal-scroll-breakpoint-down() {
    @include media-breakpoint-down(sm) {
        @content;
    }
}

%horizontal-scroll {
    @extend .flex-sm-wrap;
    @extend .flex-nowrap;
    @extend .justify-content-between;
    @extend .justify-content-sm-around;

    @media print {
        display: block;
    }

}

@mixin horizontal-scroll-items() {
    @include horizontal-scroll-breakpoint-down() {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
        min-width: 75vw;
        @content;
    }
}

// Supress anchor decoration
@mixin suppress-anchor() {
    a {
        color: inherit;
        text-decoration: inherit;
    }
}

// Include another breakpoint for .card-columns - which looks weird on medium size screens with 3 columns.
.card-columns {
    @include media-breakpoint-up(xs) {
        column-count: 1;

    }

    @include media-breakpoint-up(md) {
        column-count: 2;
    }

    @include media-breakpoint-up(lg) {
        column-count: 3;
    }
}

$bg-info-transparent: fade-out($info, 0.3);

.bg-info-transparent {
    background-color: $bg-info-transparent;
}

/* Height overrides */
.h-75vw {
    height: 75vw;
}

img.h-75vw {
    object-fit: contain;
}


.btn {
    font-weight: 300;
}

/* Set scroll snap on items that can be scrolled */
%snap-item-center {
    scroll-snap-align: center;
}

main {
    @extend .py-3;

    >ol,
    >ul {
        margin-bottom: 2rem;
    }
}

/* Print mixins */
@mixin print-background() {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    break-inside: avoid-page;
}

.bg-unifyorange {
    background-color: $unifyorange;
}

.bg-unifycoolgreen {
    background-color: $unifycoolgreen;
}

.bg-unifyblurple {
    background-color: $unifyblurple;
}

.bg-unifyyellow {
    background-color: $unifyyellow;
}

body {
    color: $textcolor;
}


// Include component CSS
@import "fontawesome";
@import "recaptcha";
@import "jumbotron";
@import "slide";
@import "scroll";
@import "hover-card";
@import "pricing";
@import "card";
@import "news";
@import "carousel";
@import "link";
@import "table";
@import "concertina";
@import "employee";
@import "menu";
@import "search";
@import "baseof";
@import "event";
@import "customer";
@import "keypoints";
@import "lists";
@import "banner";
@import "consent";
@import "form";
@import "pagination";
@import "footer";
@import "breadcrumbs";
@import "embed";
@import "button";
@import "meeting";
@import "career";
@import "feature";
@import "grid";
@import "single";
@import "blockquote";
@import "alert";
@import "mermaid";
@import "use-cases";
@import "front";
@import "home";
@import "feature-grid";
@import "trust";
@import "questionaire";
@import "spiderchart";
@import "toc";
@import "print";