/* Carousel overrides */
.carousel {
    overflow: hidden;
    @extend .my-3;
    @extend .p-0;

    .carousel-inner {
        img {
            @extend .d-block;
            @extend .w-100;
        }

        .carousel-caption {
            padding-left: 1rem;
            padding-right: 1rem;
            @extend .d-md-block;
            @extend .bg-info-transparent;

            h5 {
                @extend .my-0;
            }

            a {
                color: $white;
            }
        }

    }

    &.photo-carousel {
        img {
            height: 50vh;
            object-fit: contain;
            object-position: center;
        }
    }
}

.carousel.header-carousel {
    box-shadow: none;

    @extend .m-0;

    .carousel-indicators {
        @extend .mb-5;


        button {
            width: 1rem !important;
            height: 1rem !important;
            border-radius: 50%;
            border-width: 3px;
            transition: background-color 500ms, border-color 500ms;
            background: transparent;
            border-color: $gray-100;
            border-style: solid;
            @extend .mx-3;

            &.active {
                background: $gray-100;
                border-color: $gray-100;
            }
        }
    }

    .carousel-inner {

        .carousel-item {
            img {
                box-shadow: none;
                height: 1080px;
                max-height: 95vh;

                object-fit: cover;
            }

            &.carousel-caption-mobile-bottom-center {
                .carousel-caption {
                    @media (orientation: landscape) {
                        right: 2.5rem !important;
                        left: inherit !important;
                    }

                    @media (orientation: portrait) and (max-width: 1080px) {
                        top: inherit !important;
                        bottom: 5rem !important;
                    }
                }
            }

            @include media-breakpoint-up(xxl) {
                &.carousel-caption-bottom-right {
                    .carousel-caption {
                        right: 0 !important;
                        bottom: 5rem !important;
                        left: inherit;
                        top: inherit;
                    }
                }

                &.carousel-caption-top-right {
                    .carousel-caption {
                        right: 0 !important;
                        top: 8rem !important;
                        bottom: inherit;
                        left: inherit;
                    }
                }

                &.carousel-caption-center-right {
                    .carousel-caption {
                        right: 0 !important;
                        top: 50% !important;
                        bottom: inherit;
                        left: inherit;
                        transform: translateY(-50%);
                    }
                }

                &.carousel-caption-center-left {
                    .carousel-caption {
                        right: inherit;
                        top: 50% !important;
                        bottom: inherit;

                        left: 1rem !important;

                        transform: translateY(-50%);
                    }
                }

                &.carousel-caption-center-centerright {
                    .carousel-caption {
                        right: inherit;
                        top: 50% !important;
                        bottom: inherit;
                        left: 50% !important;
                        transform: translateY(-50%);
                    }
                }


                &.carousel-caption-top-left {
                    .carousel-caption {
                        right: inherit;
                        top: 8rem !important;
                        bottom: inherit;
                        left: 0 !important;
                    }
                }


            }

            &.carousel-caption-black {
                .carousel-caption {

                    &,
                    a {
                        color: $unifyblack !important;
                    }
                }
            }

            &.carousel-caption-unify-blue {
                .carousel-caption {

                    &,
                    a {
                        color: $unifyblue !important;
                    }
                }
            }

            &.carousel-caption-unify-coolgreen {
                .carousel-caption {

                    &,
                    a {
                        color: $unifycoolgreen !important;
                    }
                }
            }


            &.carousel-caption-unify-blurple {
                .carousel-caption {

                    &,
                    a {
                        color: $unifyblurple !important;
                    }
                }
            }

            &.carousel-caption-unify-orange {
                .carousel-caption {

                    &,
                    a {
                        color: $unifyorange !important;
                    }
                }
            }

            &.carousel-caption-unify-yellow {
                .carousel-caption {

                    &,
                    a {
                        color: $unifyyellow !important;
                    }
                }
            }

            .carousel-caption {
                @extend .position-absolute;
                @extend .bg-transparent;
                @extend .mx-auto;
                @extend .mx-xl-5;

                top: 50% !important;
                bottom: inherit;
                transform: translateY(-50%);

                @extend .text-center;
                @extend .text-xxl-start;

                @include media-breakpoint-up(xxxl) {

                    width: 33%;
                }

                box-shadow: none;

                h1 {
                    font-family: $tagline-font-family;

                    @include font-size(5rem);
                    line-height: 105%;

                    @media (max-height: 600px) {
                        @include font-size(2.5rem);
                        margin-bottom: 1rem !important;
                    }
                }

                @extend .mb-5;
            }

            .carousel-action {
                @extend .position-absolute;
                @extend .bg-transparent;
                @extend .mx-auto;
                @extend .mx-xl-5;
                bottom: 5.5rem;
                right: 2rem;

                @include media-breakpoint-up(xl) {
                    right: 5rem;
                }

                @extend .text-lowercase;
                font-family: $body-font-family;

                a {
                    text-decoration: none;
                    font-weight: 600;
                }

                @include font-size(1.2rem);
            }
        }
    }

    &:has(div.carousel-item.active.menu-reverse:not(.carousel-item-start), div.carousel-item.carousel-item-next.menu-reverse) {
        .carousel-indicators {

            button {
                background: transparent;
                border-color: $gray-900;
                border-style: solid;
                @extend .mx-3;

                &.active {
                    background: $gray-900;
                    border-color: $gray-900;
                }
            }
        }

    }
}