.page-container {
    @extend .container;
    @extend .mt-3;
    @extend .mb-5;
}

img.inlinelogo {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    height: 0.74em;
    margin-bottom: 0.196em;
    display: inline;
    margin-right: -0.05em;
    margin-left: 0.02em;
}

.metadata {
    @extend .row;
    @extend .my-2;
    @extend .justify-content-center;
    @extend .py-0;

    >span,
    .metadata-item {
        @extend .col;
        @extend .my-1;

        a {
            @extend .text-dark;
        }

        >svg {
            @extend .me-2;
        }
    }
}

body {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.wrapper {
    display: block;
}

main {

    h1,
    h2,
    h3 {
        @extend .pt-2;
    }

    h1 {
        @include font-size(2.2rem);
    }

    h2 {
        @include font-size(1.8rem);
    }

    h3 {
        @include font-size(1.4rem);
    }

    h4 {
        @include font-size(1.2rem);
    }
}

picture.microsoftsecurityspecialistbadge {
    img {
        width: 20em;
        @extend .mx-auto;
    }
}