/* News overrides */
.news-page {
    .metadata-item {
        @extend .mt-2;
    }
}

.card-list-container.news-list {
    @extend .container;

    .card-list {
        @include media-breakpoint-up(xs) {
            column-count: 1;
    
        }
    
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
    
        @include media-breakpoint-up(lg) {
            column-count: 3;
        }

        @extend .card-columns;
        @extend .d-block;
        @extend .my-3;

        .card-list-item.news-card {
            @extend .my-2;
            @extend .py-2;

            @extend .mx-0;
            @extend .px-0;
            @extend .mx-md-2;
            @extend .px-md-2;

            @extend .d-inline-block;
            @extend .col-12;



            .card {
                @extend .mb-4;
                @extend .mx-0;
                @extend .px-0;
                @extend .pt-0;
                @extend .border-0;
                
                overflow: hidden;

                .card-image {
                    min-height: 12rem;
                    @include card-border-shadow($card-border-radius); 
                }

                .card-header {
                    @extend .mx-2;
                    @extend .py-1;
                    @extend .my-0;
                    width: calc(100% - 1rem);

                    position: absolute;
                    top     : 1rem;

                    color           : $white;
                    background-color: $darkalpha;
                    @include card-border-shadow($card-border-radius);


                    h5 {
                        @include font-size(1.2rem);
                        @extend .my-1;
                        @extend .mx-1;

                        a {
                            color: $white;
                            text-decoration-line: none;

                            &:hover {
                                text-decoration-line: underline;
                            }
                        }
                    }
                }

                .card-body {
                    @extend .m-2;
                    @extend .pt-1;
                }
            }
        }
    }
}



.news-metadata {
    @extend .metadata;

    .card-list-component {

        @extend .col-12;
        @extend .container;

        .card-list-container {
            overflow: visible !important;

            @extend .pb-2;
        }
    }
}