.careers-page .metadata {
    svg {
        @extend .me-2;
        width: 2rem;
    }

    @extend .justify-content-start;

    > span { 
        @extend .col-6;
     }
}

.card-list-container {
    .card-list {
        .card-list-item.careers-card {
            .card {
                .card-header {
                    @extend .pb-2;

                    div {
                        svg {
                            @extend .me-2;
                        }
                    }
                }
            }
        }
    }
}